.success-panel {
    /*font-size: large;*/
    /*background-color: ;*/
    width: max(min(75vh, 90%), 50vw);
    /*width: 50vw;*/
    height: 10vh;
    border: black 1vw solid;
    color: black;
    border-radius: 20vw;
    font-size: 7vh;
}

.success-panel__success {
    background-color: palegreen;
}

.success-panel__hidden {
    display: none;
}

.success-panel__failure {
    background-color: lightcoral;
}