.App {
    text-align: center;
    background-color: #282c34;
    min-height: 100vh;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

:root {
    --correct-guess-color: lawngreen;
    --misplaced-guess-color: yellow;
}

.App-header {
    /*  display: flex;*/
    /*  flex-direction: column;*/
    /*  align-items: center;*/
    /*  justify-content: center;*/
}
