.guess-row {
    display: flex;
    flex-direction: row;
    font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
}

.guess-row__completed {
    color: red;
}

.guess-row__unused {
    color: var(--correct-guess-color);
}

.guess-row__in-progress {
    color: var(--misplaced-guess-color);
}


.entry-box {
    --size: min(13vmin, 8vh);
    border: black 2px solid;
    height: var(--size);
    width: var(--size);
    margin: 6px;
    text-transform: capitalize;
    font-size: calc(var(--size) * 0.9);
    color: blue;
}

.entry-box__incorrect {
    background-color: grey;
}

.entry-box__correctly-placed-correct {
    background-color: var(--correct-guess-color);
}

.entry-box__misplaced-correct {
    background-color: var(--misplaced-guess-color);
}

.entry-box__in-progress {
    background-color: lightgray;
}

.entry-box__unused {
    background-color: lightgray;
}

.entry-box__selected {

}

