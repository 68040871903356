
.keyboard-panel {
    position: absolute;
    bottom: 0px;
    color: black;
    width: max(min(75vh, 100%), 50vw);
}

.react-simple-keyboard.simple-keyboard.hg-theme-default {
    border: lightskyblue 3px solid;

}

.simple-keyboard.hg-layout-default .hg-button.hg__incorrect {
    background: rgb(130, 128, 123);
    color: white;
}

.simple-keyboard.hg-layout-default .hg-button.hg__misplaced-correct {
    background: rgb(254, 225, 77);
    color: white;
}

.simple-keyboard.hg-layout-default .hg-button.hg__correctly-placed-correct {
    background: rgba(124, 252, 0, 0.7);
    color: white;
}
