
.game-body {

}

.new-game-button {
    color: var(--correct-guess-color);
    cursor: pointer;
    position: absolute;
    margin-left: 20px;
    margin-top: 20px;
}

.word-length {
    position: absolute;
    margin-left: 20px;
    margin-top: 60px;
}

.word-length__select {

    font-size: calc(10px + 2vmin);
}

/*.new-game-item {*/
/*    height: 10px;*/
/*}*/

.help-button {
    color: var(--misplaced-guess-color);
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 20px;
    margin-top: 20px;
}

.gameplay-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    /*top: 23vh;*/
}

.spacer-panel {
    height: 12vh;
}

.toaster {
    font-size: calc(10px + 1vmin);
}

.guess-panel {
    /*position: absolute;*/
    top: 10vh;
}

